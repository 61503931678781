import { addHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const resetMinutesAndSeconds = (date: Date, hours?: number): Date => {
  let result = setMilliseconds(setMinutes(setSeconds(date, 0), 0), 0);

  if (hours) {
    result = addHours(result, hours);
  }

  return result;
};

export const setLondonTimezone = (date: Date): Date => {
  return new Date(
    formatInTimeZone(date, 'Europe/London', 'MM/dd/yyyy HH:mm:ss')
  );
};

export function removeTrailingZ(date: string | Date): string | Date {
  if (typeof date !== 'string') {
    return date;
  }

  if (date.endsWith('Z')) {
    return date.slice(0, -1);
  }

  return date;
}
