import { PersonModel } from '@models/person.model';

export enum DocumentNotificationStatusEnum {
  Approved = 'approved',
  NotVerified = 'not_verified',
  Declined = 'declined',
  Pending = 'pending',
  Expired = 'expired'
}

export class DocumentModel {
  accountId: number;
  createdBy: number;
  dateCreated: Date;
  documents: { [key: string]: string }[];
  isDeleted: boolean;
  personId: string;
  status: DocumentStatusEnum;
  id: string;
  type: DocumentTypeEnum;
  birthDate?: string;
  idNumber?: string;
  isAcceptable: boolean;

  static fromJson(json: any): DocumentModel {
    return {
      accountId: json.account_id,
      createdBy: json.created_by,
      dateCreated: json.date_created,
      documents: json.documents,
      isDeleted: json.is_deleted,
      isAcceptable: json.is_acceptable,
      personId: json.person_id,
      status: json.status,
      id: json._id,
      type: json.type
    };
  }
}

export enum DocumentStatusEnum {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined'
}

export enum DocumentTypeEnum {
  Id = 'id',
  AuthenticationPhoto = 'authentication photo',
  UnalteredPhoto = 'unaltered photo'
}

export class DocumentNotificationModel {
  id: string;
  isSeen: boolean;
  documentId: string;
  status: DocumentNotificationStatusEnum;
  personId: string;
  dateCreated: Date;
  accountId: number;
  person?: PersonModel;
  docType: DocumentTypeEnum;

  static fromJSON(json: any): DocumentNotificationModel {
    return {
      id: json._id,
      isSeen: json.is_seen,
      documentId: json.document_id,
      status: json.status,
      personId: json.person_id,
      dateCreated: new Date(json.date_created),
      docType: json.doc_type,
      accountId: json.account_id
    };
  }
}

export interface IDocumentNotificationResponse {
  notifications: DocumentNotificationModel[];
  count: number;
}

export interface IDocumentNotificationCountResponse {
  count: number;
}

export enum IconsClass {
  CheckCircle = 'task_alt',
  ExclamationCircle = 'error_outline',
  TimesCircle = 'highlight_off',
  History = 'history'
}

export const DOCUMENT_NOTIFICATIONS_STATUS_ICONS = {
  [DocumentNotificationStatusEnum.Approved]: {
    icon: IconsClass.CheckCircle,
    color: 'text-success'
  },
  [DocumentNotificationStatusEnum.Declined]: {
    icon: IconsClass.ExclamationCircle,
    color: 'text-warn'
  },
  [DocumentNotificationStatusEnum.NotVerified]: {
    icon: IconsClass.TimesCircle,
    color: 'text-sort-grey'
  },
  [DocumentNotificationStatusEnum.Pending]: {
    icon: IconsClass.History,
    color: 'text-accent'
  },
  [DocumentNotificationStatusEnum.Expired]: {
    icon: IconsClass.ExclamationCircle,
    color: 'text-warn'
  }
};

export enum ManualUploadedDocumentEnum {
  IdCard = 'ID_CARD',
  DrivingLicense = 'DRIVING_LICENSE',
  Passport = 'PASSPORT',
  ResidencePermit = 'RESIDENCE_PERMIT'
}
