import { ProfileFormDataModel } from '@models/profile-form-data.model';
import { MediaConfigsModel } from '@models/media.model';
import { AccountFormModel } from '@models/account-form.model';
import { CommerceOptionsFormDataModel } from '@models/commerce-options-form-data.model';

export interface IFormConfigs {
  profileFormDataConfigs: ProfileFormDataModel;
  mediaConfigs: MediaConfigsModel;
  accountFormDataConfigs: AccountFormModel;
  commerceOptionsConfigs: CommerceOptionsFormDataModel;
  isManualVerification: boolean;
}

export const FORM_CONFIGS_INITIAL_DATA: IFormConfigs = {
  profileFormDataConfigs: null,
  mediaConfigs: null,
  accountFormDataConfigs: null,
  commerceOptionsConfigs: null,
  isManualVerification: false
};
