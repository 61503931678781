import { DocumentNotificationStatusEnum } from '@models/document.model';

export class PersonModel {
  accountId: number;
  dateCreated: Date;
  flag: PersonFlagEnum;
  idBypass: boolean;
  name: string;
  id: string;
  verificationStatus: VerificationStatusEnum;
  livenessVerificationStatus: LivenessVerificationStatusEnum;
  isDeactivated: boolean;
  lastDocStatus: DocumentNotificationStatusEnum;
  requirements?: { optional: boolean };
  agreement: PersonAgreement;
  authenticationStatus: AuthenticationStatusEnum;

  static fromJson(json): PersonModel {
    return {
      accountId: json.account_id,
      dateCreated: new Date(json.date_created),
      flag: json.flag,
      idBypass: json.id_bypass,
      name: json.name,
      id: json._id,
      authenticationStatus: json.authentication_status,
      verificationStatus:
        json.verification_status || VerificationStatusEnum.NotVerified,
      isDeactivated: json.is_deactivated,
      livenessVerificationStatus: json.liveness_verification_status,
      lastDocStatus: json.last_doc_status,
      agreement: json.agreement
    };
  }
}

export enum VerificationStatusEnum {
  Approved = 'approved',
  Declined = 'declined',
  NotVerified = 'not_verified'
}

export enum AuthenticationStatusEnum {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
  NotVerified = 'not_verified'
}

export enum LivenessVerificationStatusEnum {
  Requested = 'requested',
  NotVerified = 'not_verified'
}

export interface PersonAgreement {
  aa_version: number;
  date_aa_reset: Date | null;
}

export enum PersonFlagEnum {
  System = 'system',
  Custom = 'custom'
}
