import { ImageModel } from '@models/image.model';
import { PersonModel } from '@models/person.model';
import {
  EXCLUDED_MAIN_CATEGORIES,
  EXCLUDED_SPECIALTY_CATEGORIES,
  ProfileMainCategoryEnum,
  ProfileSpecialtyCategoryEnum,
  UnitTypeEnum
} from '@models/profile-form-data.model';
import { StoryModel } from '@app/pages/my/features/stories/data-access/story.model';
import { DocumentTypeEnum } from '@models/document.model';
import { SubmitForReviewDeclineReasonType } from '@app/pages/my/features/services/data-access/models/all-services.model';

export class ProfileModel {
  id: string;
  profileId?: string;
  isMulti: boolean;
  isFast: boolean;
  personId: string;
  personIds: string[];
  accountId: number;
  isInventoryLocked: boolean;
  isAuthenticated: boolean;
  showName: string;
  tagline: string;
  age: number;
  gender: string;
  phoneNumbers: ProfilePhoneNumberModel[];
  emails: string[];
  websites: string[];
  locationId: number;
  mainCategories: string[];
  transOperation: string | null;
  visitingText: string | null;
  isVisiting: boolean;
  isVip: boolean;
  isHidden: boolean;
  inventoryId: number;
  isAnimated: boolean;
  bioText: string | null;
  offersList: string | null;
  donations: string | null;
  disclaimer: string | null;
  hairColor: string;
  eyeColor: string;
  ethnicities: string[];
  unit: UnitTypeEnum;
  height: number;
  weight: number;
  bustSize: number;
  waistSize: number;
  hipSize: number;
  cupSize: string;
  affiliation: string;
  availableTo: string[];
  specialtyCategories: ProfileSpecialtyCategoryEnum[];
  locationCategories: string[];
  locationTags: string;
  availableFor: ProfileAvailableForModel[];
  social: ProfileSocialModel[];
  dateCreated?: Date;
  dateModified?: Date;
  erosId?: number;
  revision?: ProfileModel;
  revisionMeta?: ProfileRevisionMetaModel;
  photos: ProfilePhotoModel[];
  mainPhoto?: ProfilePhotoModel;
  style: ProfileStyleEnum;
  referenceId: number;
  isAvailableNow: boolean;
  isOnline?: boolean;
  isModifiable?: boolean;
  magazineReference?: string | null;
  movieReference?: string;
  alternativeSpecialty: string[];
  isBundledCategory?: boolean;
  isOnlyBannedCategory?: boolean;
  profile?: any;
  persons?: PersonModel[];
  locationFormatted?: string;
  locationName?: string;
  pseudo_status?: string;
  date_activation_converted?: string;
  date_expiration_converted?: string;
  date_activation?: Date | string;
  date_expiration?: Date | string;
  date_expiration_time?: string;
  hasStories?: boolean;
  status?: ProfileRevisionStatusEnum;
  location?: {
    timezone: string;
    path: string;
  };
  main_photo?: ProfilePhotoModel;
  indexed_text?: any;
  stories?: StoryModel[];
  dateReveal?: Date | string;
  adVisibility: SecretAdVisibility;

  static fromJson(json: any): ProfileModel {
    return {
      id: json._id,
      isMulti: json.is_multi,
      isFast: json.is_fast,
      profileId: json.profile_id,
      personId: json.person_id,
      personIds: json.person_ids || [],
      accountId: json.account_id,
      isInventoryLocked: json.is_inventory_locked,
      isAuthenticated: json.is_authenticated,
      showName: json.showname,
      tagline: json.tagline,
      age: json.age,
      gender: json.gender,
      phoneNumbers: json.phone_numbers
        ? json.phone_numbers.map((num) => ProfilePhoneNumberModel.fromJson(num))
        : [],
      emails: json.emails ?? [],
      websites: json.websites ?? [],
      locationId: json.location_id,
      transOperation: json.trans_operation,
      visitingText: json.visiting_text,
      isVisiting: json.is_visiting,
      isVip: json.is_vip,
      isHidden: json.is_hidden,
      inventoryId: json.inventory_id,
      isAnimated: json.is_animated,
      bioText: json.isFast ? null : json.bio_text /*StaticFix*/,
      offersList: json.offers_list ?? '',
      donations: json.donations ? json.donations : '',
      disclaimer: json.isFast ? null : json.disclaimer /*StaticFix*/,
      hairColor: json.hair_color,
      eyeColor: json.eye_color,
      ethnicities: json.ethnicities ?? [],
      unit: json.unit,
      height: json.height,
      weight: json.weight,
      bustSize: json.bust_size,
      waistSize: json.waist_size,
      hipSize: json.hip_size,
      cupSize: json.cup_size,
      affiliation: json.affiliation,
      availableTo: json.available_to ? json.available_to : [],
      mainCategories: json.main_categories
        ? json.main_categories.filter(
            (cat: ProfileMainCategoryEnum) =>
              !EXCLUDED_MAIN_CATEGORIES.includes(cat)
          )
        : [] /*StaticFix*/,
      specialtyCategories: json.specialty_categories
        ? json.specialty_categories.filter(
            (cat: ProfileSpecialtyCategoryEnum) =>
              !EXCLUDED_SPECIALTY_CATEGORIES.includes(cat)
          ) /*StaticFix*/
        : [],
      alternativeSpecialty: json.alternative_specialty
        ? json.alternative_specialty
        : [],
      locationCategories: json.location_categories
        ? json.location_categories
        : [],
      isBundledCategory: json.main_categories
        ? json.main_categories.some((cat: ProfileMainCategoryEnum) =>
            EXCLUDED_MAIN_CATEGORIES.includes(cat)
          ) &&
          !json.main_categories.every((cat: ProfileMainCategoryEnum) =>
            EXCLUDED_MAIN_CATEGORIES.includes(cat)
          )
        : false,
      isOnlyBannedCategory: json.main_categories
        ? json.main_categories.every((cat: ProfileMainCategoryEnum) =>
            EXCLUDED_MAIN_CATEGORIES.includes(cat)
          )
        : false,
      locationTags: null /*StaticFix*/, // json.location_tags,
      availableFor: json.available_for
        ? json.available_for.map((afor) =>
            ProfileAvailableForModel.fromJson(afor)
          )
        : [],
      social: json.social
        ? ProfileSocialModel.fromJson(json.social)
        : ProfileSocialModel.empty(),
      erosId: json.eros_id,
      isAvailableNow: json.is_available_now,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      revision: json.revision,
      revisionMeta: json.revision
        ? ProfileRevisionMetaModel.fromJson(json.revision)
        : null,
      photos: json.photos
        ? json.photos.map((photo) => ProfilePhotoModel.fromJson(photo))
        : [],
      style: json.style ?? ProfileStyleEnum.Default,
      referenceId: json.reference_id,
      magazineReference: json.magazine_reference,
      movieReference: json.movie_reference,
      mainPhoto: json.photos ? json.photos[0] : null,
      dateReveal: json.date_reveal || null,
      stories: [],
      adVisibility: json.ad_visibility
    };
  }

  static empty(): ProfileModel {
    return {
      id: null,
      isFast: false,
      emails: [],
      phoneNumbers: [],
      tagline: '',
      gender: '',
      age: null,
      showName: '',
      revision: null,
      isOnline: false,
      accountId: null,
      affiliation: '',
      alternativeSpecialty: [],
      availableFor: [],
      availableTo: [],
      bioText: '',
      offersList: '',
      donations: '',
      bustSize: null,
      cupSize: null,
      dateCreated: null,
      dateModified: null,
      disclaimer: undefined,
      erosId: null,
      ethnicities: [],
      eyeColor: null,
      hairColor: null,
      height: null,
      hipSize: null,
      inventoryId: null,
      isAnimated: false,
      isAuthenticated: false,
      isAvailableNow: false,
      isInventoryLocked: false,
      isMulti: false,
      isVip: false,
      isHidden: false,
      isVisiting: false,
      locationCategories: [],
      locationId: null,
      locationTags: null,
      magazineReference: undefined,
      mainCategories: [],
      mainPhoto: null,
      movieReference: undefined,
      personId: null,
      personIds: [],
      photos: [],
      style: ProfileStyleEnum.Empty,
      profileId: null,
      referenceId: null,
      revisionMeta: undefined,
      social: ProfileSocialModel.empty(),
      specialtyCategories: [],
      transOperation: null,
      unit: null,
      waistSize: null,
      websites: [],
      weight: null,
      visitingText: null,
      stories: [],
      adVisibility: null
    };
  }

  static toJson(profile: ProfileModel): IProfileToJson {
    return {
      profile_id: profile.profileId,
      showname: profile.showName,
      tagline: profile.tagline,
      age: profile.age,
      style: profile.style ?? ProfileStyleEnum.Default,
      gender: profile.gender,
      phone_numbers: (() => {
        if (profile.phoneNumbers.length === 0) return null;
        return profile.phoneNumbers.map(
          ({ number, hasViber, hasWhatsapp, hasTextOnly }) => ({
            number,
            has_viber: !!hasViber,
            has_whatsapp: !!hasWhatsapp,
            has_text_only: !!hasTextOnly
          })
        );
      })(),
      emails: profile.emails.length === 0 ? null : profile.emails,
      websites: null,
      location_id: profile.locationId,
      main_categories: profile.mainCategories,
      photos: profile.photos,
      trans_operation: profile.transOperation || null,
      visiting_text: profile.visitingText || null,
      is_visiting: profile.isVisiting,
      is_vip: profile.isVip,
      inventory_id: profile.inventoryId,
      is_animated: profile.isAnimated,
      bio_text: profile.bioText || null,
      offers_list: profile.offersList || null,
      donations: profile.donations || null,
      hair_color: profile.hairColor || null,
      eye_color: profile.eyeColor || null,
      ethnicities:
        profile.ethnicities.length === 0 ? null : profile.ethnicities,
      unit: profile.unit,
      height: profile.height || null,
      weight: profile.weight || null,
      bust_size: profile.bustSize || null,
      waist_size: profile.waistSize || null,
      hip_size: profile.hipSize || null,
      cup_size: profile.cupSize || null,
      affiliation: profile.affiliation || null,
      available_to:
        profile.availableTo.length === 0 ? null : profile.availableTo,
      specialty_categories:
        profile.specialtyCategories.length === 0
          ? null
          : profile.specialtyCategories,
      alternative_specialty:
        profile.alternativeSpecialty.length === 0
          ? null
          : profile.alternativeSpecialty,
      location_categories:
        profile.locationCategories.length === 0
          ? null
          : profile.locationCategories,
      location_tags: null,
      available_for:
        profile.availableFor.length === 0 ? null : profile.availableFor,
      social: profile.social
    };
  }
}

export interface IProfileToJson {
  profile_id?: string;
  account_id?: number;
  showname: string;
  tagline: string;
  age: number;
  gender: string;
  phone_numbers: {
    number: string;
    has_viber: boolean;
    has_whatsapp: boolean;
    has_text_only: boolean;
  }[];
  emails: string[];
  websites: string[];
  location_id: number;
  main_categories: string[];
  trans_operation: string | null;
  visiting_text: string | null;
  is_visiting: boolean;
  is_vip: boolean;
  inventory_id: number;
  is_animated: boolean;
  bio_text: string | null;
  offers_list: string | null;
  donations: string | null;
  hair_color: string;
  eye_color: string;
  ethnicities: string[];
  unit: UnitTypeEnum;
  height: number;
  weight: number;
  bust_size: number;
  waist_size: number;
  hip_size: number;
  cup_size: string;
  affiliation: string;
  available_to: string[];
  specialty_categories: ProfileSpecialtyCategoryEnum[];
  location_categories: string[];
  location_tags: string;
  available_for: ProfileAvailableForModel[];
  social: ProfileSocialModel[];
  photos: ProfilePhotoModel[];
  style: ProfileStyleEnum;
  alternative_specialty: string[];
}

export class ProfilePhoneNumberModel {
  number: string;
  hasViber: boolean;
  hasWhatsapp: boolean;
  hasTextOnly: boolean;

  static fromJson(json: any): ProfilePhoneNumberModel {
    return {
      number: json.number,
      hasViber: Boolean(json.has_viber),
      hasWhatsapp: Boolean(json.has_whatsapp),
      hasTextOnly: Boolean(json.has_text_only)
    };
  }
}

export class ProfileAvailableForModel {
  type: ProfileAvailableForEnum = null;
  address: string = null;
  latitude: number = null;
  longitude: number = null;
  radius: number = null;
  checked?: boolean = false;

  constructor(type: ProfileAvailableForEnum) {
    this.type = type;
  }

  static fromJson(json: any): ProfileAvailableForModel {
    return {
      type: json.type,
      address: json.address,
      latitude: json.latitude,
      longitude: json.longitude,
      radius: json.radius
    };
  }
}

export enum ProfileAvailableForEnum {
  Incall = 'incall',
  Outcall = 'outcall'
}

export class ProfileSocialModel {
  type: ProfileSocialType;
  handle: string;

  static fromJson(
    json: Array<{ type: ProfileSocialType; handle: string | null }>
  ): ProfileSocialModel[] {
    return ProfileSocialModel.empty().map((item) => {
      const profileSocialLink = json.find((_item) => _item.type === item.type);
      return profileSocialLink ? profileSocialLink : item;
    });
  }

  static empty() {
    return [
      { type: ProfileSocialType.Youtube, handle: null },
      { type: ProfileSocialType.Facebook, handle: null },
      { type: ProfileSocialType.Snapchat, handle: null },
      { type: ProfileSocialType.Vine, handle: null },
      { type: ProfileSocialType.Tumblr, handle: null },
      { type: ProfileSocialType.Periscope, handle: null },
      { type: ProfileSocialType.Instagram, handle: null },
      { type: ProfileSocialType.Tiktok, handle: null }
    ];
  }
}

export enum ProfileSocialType {
  Youtube = 'youtube',
  Facebook = 'facebook',
  Snapchat = 'snapchat',
  Vine = 'vine',
  Tumblr = 'tumblr',
  Periscope = 'periscope',
  Instagram = 'instagram',
  Tiktok = 'tiktok'
}

export enum ProfileStyleEnum {
  Empty = '',
  Default = 'default',
  Blue = 'blue',
  Purple = 'purple',
  Green = 'green',
  Pink = 'pink',
  Red = 'red',
  Brown = 'brown'
}

export enum ProfileRevisionStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined'
}

export class ProfileRevisionMetaModel {
  createdBy: number;
  status: ProfileRevisionStatusEnum;
  number: number;
  processedBy: number;
  modifiedBy: number;
  isModifiable: boolean;
  isIncomplete: boolean;
  isDeleted: boolean;
  isApprovable: boolean;
  dateCreated: Date;
  dateModified: Date;
  personIds?: string[];
  showName: string;
  isVisiting: boolean;
  profileId: string;
  declineMessage?: string;
  declineReason?: keyof SubmitForReviewDeclineReasonType;

  static fromJson(json: any): ProfileRevisionMetaModel {
    return {
      status: json.status,
      number: json.number,
      createdBy: json.created_by,
      processedBy: json.processed_by,
      modifiedBy: json.modified_by,
      isModifiable: json.is_modifiable,
      isIncomplete: json.is_incomplete,
      isApprovable: json.is_approvable,
      isDeleted: json.is_deleted,
      dateCreated: json.date_created ? new Date(json.date_created) : null,
      dateModified: json.date_modified ? new Date(json.date_modified) : null,
      showName: json.showname,
      isVisiting: json.is_visiting,
      profileId: json.profile_id,
      declineMessage: json.decline_message || null,
      declineReason: json.decline_reason || null
    };
  }
}

export class ProfilePhotoModel {
  id: string;
  imageId: string;
  status: ProfilePhotoEnum;
  number: number;
  personId: string;
  processedBy?: number;
  dateProcessed?: Date;
  dateCreated: Date;
  isMain?: boolean;
  isNew?: boolean;
  media?: ImageModel;

  cId?: string;
  processing?: boolean;
  loaded?: boolean;
  error?: Error;
  person?: PersonModel;
  isProcessing?: boolean;

  static fromJson(json: any): ProfilePhotoModel {
    return {
      id: json._id || json.id,
      imageId: json.image_id,
      personId: json.person_id,
      processedBy: json.processed_by,
      dateProcessed: json.date_processed ? new Date(json.date_processed) : null,
      dateCreated: new Date(json.date_created),
      isMain: json.is_main,
      isNew: json.is_new,
      status: json.status,
      number: json.number,
      media: null
    };
  }
}

export interface ICreateProfileReqBody {
  personId: string | null;
  isMulti: boolean;
  isFast: boolean;
  adVisibility?: SecretAdVisibility;
}

export enum SecretAdVisibility {
  Public = 'public',
  SecretWithContact = 'secret_with_contact',
  SecretWithoutContact = 'secret_without_contact'
}

export enum InventoryEnum {
  Standard = 'standard',
  Visiting = 'visiting'
}

export enum ProfilePhotoEnum {
  Pending = 'pending',
  Declined = 'declined'
}

export class OnlineProfileIDs {
  id: number;
  profileId: string;

  static fromJson(json: any): OnlineProfileIDs {
    return {
      id: json.id,
      profileId: json.profile_id
    };
  }
}

export const PROFILE_CHANGES_MAX_COUNT = 3;

export interface ICreateDocumentReqBody {
  documents: File[];
  instructions?: string;
  person_id: string;
  type: DocumentTypeEnum;
}

export interface ICreateSingleDocumentReqBody {
  accountId: number;
  personId: string;
  type: DocumentTypeEnum;
  instructions: string;
  fileId: string;
}

export interface ICreateDocumentResBody {
  id: string;
  person_id: string;
  type: string;
  documents: { id: string }[];
}

export interface ICheckBlacklistedWordReqBody {
  account_id?: number;
  bio_text?: string;
  showname?: string;
  tagline?: string;
  visiting_text?: string;
  offers_list?: string;
  location_tags?: string;
}

export const extractSensitiveFields = (
  data: Partial<ProfileModel>
): ICheckBlacklistedWordReqBody => {
  return {
    bio_text: data.bioText,
    showname: data.showName,
    tagline: data.tagline,
    visiting_text: data.visitingText,
    offers_list: data.offersList,
    location_tags: data.locationTags
  };
};
