import { environment } from '@env/environment';
import { MediaPurposeEnum } from '@models/media.model';
import { AuthService } from '@services/auth.service';

export class ImageModel {
  status: ImageStatusEnum;
  processStatus: ImageProcessStatusEnum;
  imageId: string;
  hash: string;
  ext: ImageExtensionEnum;
  thumbName: ThumbNameEnum;
  isAdjusted?: boolean;
  filename: string;
  width: number;
  height: number;
  path: string;
  fullPath: string;
  type: ImageTypeEnum;
  dateCreated: Date;
  cId?: string;
  cropArgs: {
    x: string;
    y: string;
  } | null;
  url: string;

  static fromJson(json: any): ImageModel {
    return {
      status: json.status,
      imageId: json.image_id,
      hash: json.hash,
      ext: json.ext,
      thumbName: json.thumb_name,
      isAdjusted: json.is_adjusted,
      filename: json.filename,
      processStatus: ImageProcessStatusEnum.Finished,
      width: json.width,
      height: json.height,
      path: `${json.path}${AuthService.getMediaHashQuery()}`,
      fullPath: `${environment.mediaUrl}/${json.path}${AuthService.getMediaHashQuery()}`,
      type: json.type,
      dateCreated: new Date(json.date_created),
      cropArgs: json.crop_args ? json.crop_args : null,
      url: json.url
    };
  }
}

export enum ThumbNameEnum {
  Profile = 'profile',
  Original = 'original',
  Thumb117 = 'thumb_117',
  Thumb558 = 'thumb_558',
  Thumb265 = 'thumb_265',
  Thumb248 = 'thumb_248',
  Thumb186 = 'thumb_186',
  Thumb60 = 'thumb_60'
}

export enum ImageTypeEnum {
  Resize = 'resize',
  Rescrop = 'rescrop'
}

export enum ImageExtensionEnum {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png'
}

export enum ImageProcessStatusEnum {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed'
}

export enum ImageStatusEnum {
  Pending = 'pending',
  Finished = 'finished'
}

export type UploadProfileImageReqPayload = {
  file: File;
  accountId: number;
  purpose?: MediaPurposeEnum;
  cId: string;
};

export interface IUploadFileRes {
  body: { id: string };
  msg: string;
  status: EventMessageStatusEnum;
  percentDone: number;
  cId: string;
  error?: any;
}

export enum EventMessageStatusEnum {
  Pending = 'pending',
  Finished = 'finished'
}
